import React from 'react';
import NavigationBar from '../../navigationbar';
import Footer from '../../footer';

import './gbv.css';

function Warum() {
  return (
    <>
        <NavigationBar/>
        <section className="gbv-section">
          <div className="gbv-content">
            <h2>Warum Vorbereitung?!</h2>
            <br/>
            <p>Ich habe durch meine beiden sehr unterschiedlichen Schwangerschaften und Geburten gelernt, <strong>wie wichtig eine gute (mentale) Geburtsvorbereitung</strong> ist und wie negativ Schwangerschaft und Geburt leider in der Gesellschaft gesehen werden!</p>
            <h3>Das möchte ich ändern!</h3>
            <p>Ich habe am eigenen Leib erfahren, wie wirkungsvoll man seine Geburt<br/>
              mit beeinflussen kann und möchte genau das an so viele Frauen<br/>
              wie möglich weiter geben! Als Yogalehrerin und Coach kann ich dich hier einfühlsam und stärkend an die Hand nehmen und dir den Weg zeigen.<br/>
              <strong>Es ist wichtig, wie wir geboren werden!</strong><br/>
              Nicht nur für das Baby, sondern auch für die Mutter und die ganze Familie!
            </p>
            <h3>Deshalb möchte ich dich in deinem Vertrauen stärken,</h3>
            <h3>dir zeigen, wie du (wieder) in deine weibliche Kraft kommst</h3>
            <h3>und dir dafür handfeste Strategien mit an die Hand geben!</h3>
          </div>
        </section>

        <Footer color="geburtsvorbereitung"/>
    </>
  );
}

export default Warum;
