import React from 'react';
import NavigationBar from '../navigationbar';
import Footer from '../footer';

import image1 from '../assets/LogoJasmin.png';
import image2 from '../assets/silhouette-of-woman-with-sun-behind.jpg';

function UeberMich() {
  return (
    
    <>
        <NavigationBar/>
        
        <section>
          <div className="gbv-image-text-container">
            <div className="gbv-image-text-item">
                <img className='gbv-image img-jasmin' src={image1} alt="Jasmin"/>
                <h1> Über mich</h1>
                <p >
                  Hallo, mein Name ist Jasmin.<br/>
                  Ich bin Bewusstseins-Coach und vom Power Yoga Institute in Hamburg Level 1 zertifizierte Yoga-Lehrerin mit zusätzlichen Zertifikaten im Yin Yoga und Kinderyoga.<br/>
                  Ich bin durch meine erste Schwangerschaft zum Yoga gekommen. Die Yogapraxis und Meditationen waren für mich ein wichtiger Bestandteil meiner Geburtsvorbereitungen.<br/>
                  Aber den wirklichen und vor allem tiefgreifenden Nutzen von Yoga habe ich erst während und nach meiner Ausbildung wirklich erfahren und verstanden. Ich bin immer noch und immer wieder ganz fasziniert davon, was Yoga alles bewirken kann! Wieviel Lebensenergie man auf einmal zurück gewinnt und wie die Yogapraxis zu einem viel positiveren Leben führen kann, das grenzt schon fast an Zauberei!
                  </p>
                  <br/>
                  <p >
                  <strong >Lass mich dir dieses Wunder zeigen<br/>
                    und erfahre es selbst!<br/>
                    Lass uns zusammen Yoga machen!  <br/>
                  </strong>
                </p>
            </div>
          </div>
        </section>

        <section>
          <div className="gbv-image-text-container">
            <div class="gbv-image-text-item">
                <img className='gbv-image' src={image2} alt="meditierende Yoga Frau im sonnenuntergang"/>
                <h1>Meine Vision</h1>
                <p >
                Ich möchte mit meinen Kursen dazu beitragen eine schönere und entspanntere Welt zu schaffen! Eine Gesellschaft, in der jeder sich selbst liebt und aus dieser inneren Stärke heraus tolerant und frei sein kann, sich entfalten kann und auch andere sein lassen kann, wie sie sind.<br/>
                Dazu kann Yoga beitragen. Aber ich unterstütze dich auch mit erprobten Techniken und Gedankenanstößen auf der geistigen Ebene, für nachhaltige Veränderungen hin zu einem positiveren Leben!<br/>
                Besonders wichtig ist mir, die Wahrnehmung<br/>
                und das Denken über Schwangerschaft und vor allem Geburt zu verändern! Es ist in Deutschland (leider) noch nicht so verbreitet, wie in anderen Ländern, aber: Geburt muss nicht voller Interventionen und Schmerzen sein! <br/>
                <strong>Geburt kann sanft,<br/>
                schön und kraftvoll sein,<br/>
                genau wie unser Leben an sich!</strong><br/>
                Wenn du mir nicht glaubst, dann komm in einen meiner Kurse und ich zeig es dir!
                </p>
            </div>
          </div>
        </section>

        <Footer/>
    </>
  );
}

export default UeberMich;
