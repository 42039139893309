import React from 'react';
import NavigationBar from '../../navigationbar';
import Footer from '../../footer';

import './gbv.css';

import GBV_Logo from '../../assets/Frauen gelber Hintergrund/1.jpg';

function Geburtsvorbereitung() {
  return (
    <>
      <NavigationBar/>
       
      <section className="gbv-picture">
        <div className="gbv-img-content">
          <img className="gbv-main-logo" src={GBV_Logo} alt="gbv mit Herz"/>
        </div>
        <div className="gbv-content">
          <strong>Du hast Angst vor Schmerzen<br/>
            bei der Geburt?<br/>
            Entdecke deine KRAFT<br/>
            und unterstütze deinen Körper! 
          </strong>
          <p>
            Möchtest du die Geburt deines Kindes selbst in die Hand nehmen? <br/>
            Selbst gestalten, wie deine Geburt wird, anstatt den "Schmerzen" ausgeliefert zu sein?<br/>
            Raus aus der Ohnmacht, mit Kopfsprung rein in die Selbstbestimmung und Kraft?<br/>
            Ja, das geht!<br/>
            Ich kann dir zeigen, wie du das schaffst!<br/>
            <strong>Für weniger Angst und Schmerzen,<br/>
              für mehr selbstbestimmte und<br/>
              kraftvolle Geburten!
            </strong>
          </p>
        </div>
      </section>

      <section>
        <div className='gbv-explain'>
          <p>
            Die Annahme, man könne nichts dagegen tun, dass Geburt halt schmerzhaft und unsicher sei, ist weit verbreitet und für viele Frauen pure und harte, manchmal sogar traumatische Realität! Das alles ist Geburt aber nicht per se, es kann auch ganz anders
            aussehen! Du kannst lernen deinen Körper und Geist zu unterstützen, mit ihm zu arbeiten statt gegen ihn und auf diese Weise deine Geburt zu einem kraftvollen undwunderschön-stärkenden Erlebnis werden lassen (und dich dadurch mit vorherigen negativen Erfahrungen zum Teil versöhnen)!<br/>
            <strong>Trau dich und gehe mutig einen neuen Weg der Geburtsvorbereitung!</strong><br/>
            Für die Geburt, die du dir innerlich wünscht und für die du, dein Körper und auch dein Baby von Natur aus gemacht sind!<br/>
            <br/>
            <strong>In meinem Kurs STARK in die Geburt und Wochenbett! lernst und übst du Stück für Stück (mit deinem Geburtsbegleiter), was dich in deiner Geburt unterstützen kann, um eine selbstbestimmte Geburt zu erleben!</strong>
          </p>
        </div>
      </section>

      <section>
        <div className='gbv-explain-2'>
          <div >
            <strong>Zur Zeit hast du 3 Wahlmöglichkeiten <br/>
              für deine mentale Geburtsvorbereitung mit mir:
            </strong>
          </div>
          <div className='gbv-list'>
            <div className='gbv-listitem'>
              <p>Es finden regelmäßig <strong>Gruppenkurse</strong> zur mentalen Geburts-vorbereitung statt. </p>
            </div>
            <div className='gbv-listitem'>
              <p>Rund um Lauenbrück können wir uns zu einem <strong>persönlichen Coaching vor Ort</strong> treffen.</p>
            </div>
            <div className='gbv-listitem'>
              <p><strong>Online</strong> über <strong>Zoom</strong> hast die Möglichkeit von überall aus ein individuelles Coaching mit mir zu machen.</p>
            </div>
            <div className='gbv-listitem'>
              <p><strong>Coming soon:</strong><br/>Ich gestalte und erarbeite gerade einen Online-Videokurs.</p>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className='gbv-explain-3'>
          <p className='gbv-p'>Egal, ob du dein erstes Baby erwartest oder schon Kinder hast:</p>
          <strong>Ich passe mein Angebot gerne ganz individuell auf dich an,</strong>
          <strong>je nachdem was du und dein Geburtsbegleiter braucht!</strong>
        </div>
      </section>

      <section>
        <div className='gbv-explain-2'>
          <div >
            <h2 className='pacifico'>Für detailliere Infos lies gerne <br/>
              auf den anderen Seiten weiter!
            </h2>
          </div>
          <div className='gbv-list'>
            <div className='gbv-listitem listitem-center'>
              <h3 className='pacifico'>Warum Vorbereitung?</h3>
              <p>Klingt total übertrieben oder? Früher haben die Frauen sich auch nicht "vorbereitet"... Klingt nach Arbeit und Stress!
                  Warum solltest du dir sowas antun?
              </p>
              <a href='/warum' className="gbv-card-button">mehr lesen</a>
            </div>
            <div className='gbv-listitem listitem-center'>
              <h3 className='pacifico'>Mein Herzenswunsch</h3>
              <p>Warum ich mentale Geburtsvorbereitung anbiete und mir dieses Thema so unglaublich wichtig ist...
              </p>
              <a href='/herzenswunsch' className="gbv-card-button">mehr lesen</a>
            </div>
            <div className='gbv-listitem listitem-center'>
              <h3 className='pacifico'>Aufbau des Kurses</h3>
              <p>Schau dir die Bestandteile meiner Vorbereitung auf deine Geburt ohne Angst genau an!
              </p>
              <a href='/aufbaukurs' className="gbv-card-button">mehr lesen</a>
            </div>
          </div>
        </div>
      </section>

      <section className="gbv-picture">
        <div className="gbv-img-content">
          <img className="gbv-main-logo" src={GBV_Logo} alt="gbv mit Herz"/>
        </div>
        <div className="gbv-content">
          <strong>Du bist bereit, die Geburt deines Kindes in deine Hände zu nehmen und möchtest sie aktiv selber gestalten?
          </strong>
          <p>
            Dann melde dich bei mir für ein kostenloses Kennenlerngespräch und wir schauen uns einfach einmal gemeinsam an, was du noch brauchst, um die Geburt deines Babys zu DEINER stärkenden Geburt werden zu lassen, die du und dein Baby euch wünscht!
          </p>
        </div>
      </section>

      <Footer color="geburtsvorbereitung"/>
    </>
  );
}

export default Geburtsvorbereitung;
