import React from 'react';
import NavigationBar from '../../navigationbar';
import Footer from '../../footer';

import swanger1 from '../../assets/Yoga/SSy/pregnancy-yoga-and-fitness-concept.jpg';
import swanger2 from '../../assets/Yoga/SSy/pregnant-young-woman-doing-prenatal-yoga-variation-of-easy-posture.jpg';

function YogaSchwangere() {
  return (
    <>
        <NavigationBar/>
        
        <section className='schwanger-head'>
          <div className='schwanger-img-container'>
            <div className='image-wrapper'>
              <img src={swanger1} className='schwanger-img' alt="schwanger1"/>
            </div>
          </div>
          <div className='schwanger-img-container'>
            <div className='image-wrapper'>
              <img src={swanger2} className='schwanger-img' alt="schwanger2"/>
            </div>
          </div>
        </section>
        <section className='schwanger-head-label'>
          <h2>für Schwangere</h2>          
          <p>Dieser Kurs ist eine Mischung aus leichten Übungen und Austausch mit anderen Schwangeren. 
Du kannst hier üben deinen ganzen Körper für die Herausforderungen deiner Schwangerschaft und Geburt zu stärken und Stress zu lösen (körperlich und geistig).</p>
        </section>

        <section className='schwanger-details'>
            <h3>Wann & wo findet der Kurs statt?</h3>
            <br/>
            <p><strong>Mittwochs 17.30 bis 19.00 Uhr</strong>, in der alten Sparkasse (Dorfgemeinschaftshaus)<br/>Bahnhofstraße 36 in 27389 <strong>Lauenbrück</strong></p>
            {/* <p><strong>Mittwochs 19.30 bis 21.00 Uhr</strong>, Simbav e.V. Zentrum für Familien Am Pferdemarkt 3 in 27356 <strong>Rotenburg (Wümme)</strong></p> */}
            <br/>
            {/* <hr className='solid'/>
            <br/>
            <h3>Was bietet dir der Kurs?</h3>
            <ul>
              <li>Sanfte Bewegung, Streckung und Dehnung unterstützen dich geistig und körperlich in der Schwangerschaft und während der Geburt deines Babys</li>
              <li>Lerne, wie du den typischen Schwangerschaftsbeschwerden durch Yoga vorbeugen oder sie lindern kannst</li>
              <li>Trainiere deinen Geist mit erprobten Mentaltechniken für deine entspannte Schwangerschaft und eine sanfte Geburt deines Babys</li>
              <li>Es gibt im Kurs immer Zeit für Austausch, aber auch für Fragen, die dir auf der Seele brennen. Du kannst hier von Erfahrungen und dem Wissen der anderen super profitieren!</li>
              <li>Erfahre, welche Bewegungen dich und dein Baby während der Geburt unterstützen</li>
              <li>Übe verschiedene Atem- und Entspannungstechniken, die dich bei deiner Geburtsreise begleiten können</li>
            </ul>
            <br/> */}
            <hr className='solid'/>
            <br/>
            <h3>Was brauchst du für den Kurs?</h3>
            <br/>
            <p>Bitte bring dir folgendes mit:</p>
            <ul>
              {/* <li>eine leichte (Woll-)<strong>Decke</strong></li> */}
              <li>dein großes <strong>Stillkissen</strong> (oder einfach ein anderes, sehr dickes Kissen)</li>
              <li>ein <strong>Getränk</strong></li>
              <li>einen <strong>Snack</strong></li>
            </ul>
            {/* <br/>
            <p>Wenn du eine eigene Matte oder Blöcke hast, kannst du diese auch gerne mitbringen.</p> */}
            <br/>
            <hr className='solid'/>
            <br/>
            <h3>Träger und Kosten</h3>
            <p>10 Euro pro Termin.</p>
            {/* <p><strong>Spende:</strong> 7 Euro pro Kursstunde für Simbav-Mitglieder, 10 Euro ohne Mitgliedschaft.</p> */}
            <br/>
            <p className='schwanger-center-p'><strong>Der Kurs ist fortlaufend, du kannst jederzeit einsteigen!</strong></p>
            <p className='schwanger-center-p'><strong>Melde dich einfach <a href='/Kontakt'>bei mir!</a></strong></p>
        </section>

        <Footer color="yoga"/>
    </>
  );
}

export default YogaSchwangere;
