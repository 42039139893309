import React from 'react';
import NavigationBar from '../navigationbar';
import Footer from '../footer';
function Kontakt() {
  return (
    <>
        <NavigationBar/>
        <section className='section'>
          <h1>Kontakt</h1>
          <p>Jasmin Stoll</p>
          <p>E-Mail: herznah@gmx.de</p>
          <p>Telefon: 01578 29 77 479</p>
          <div className='rounded-row'>
            <p>Kontakt per WhatsApp</p>
            <a href='https://wa.me/4915782977479' target='_blank' rel='noreferrer'>Nachricht schreiben</a>
          </div>

          <br/>
          <p>Schau dir auch gerne meine Bewertungen bei <a className="googlelink" href="https://www.google.de/maps/place/Herznah+%E2%80%90+mentale+Geburtsvorbereitung,+Yoga+und+Coaching/@53.1956541,9.5728,16.54z/data=!4m6!3m5!1s0x47b1a7a02c97bdd9:0x4ec8a716531e90b4!8m2!3d53.1962746!4d9.576781!16s%2Fg%2F11v10tfk2r?entry=ttu" target='_blank' rel='noreferrer'>Google Maps</a> an!</p>
        </section>
        
        <Footer/>
    </>
  );
}

export default Kontakt;
