import React from 'react';
import NavigationBar from '../../navigationbar';
import Footer from '../../footer';

import mama1 from '../../assets/Yoga/Neu-Mamas/mother-with-little-baby-boy-practice-yoga(1).jpg';
import mama2 from '../../assets/Yoga/Neu-Mamas/mother-with-little-baby-boy-practice-yoga.jpg';

function YogaMamas() {
  return (
    <>
        <NavigationBar/>
        <section className='schwanger-head'>
          <div className='schwanger-img-container'>
            <div className='image-wrapper'>
              <img src={mama1} className='schwanger-img' alt="schwanger1"/>
            </div>
          </div>
          <div className='schwanger-img-container'>
            <div className='image-wrapper'>
              <img src={mama2} className='schwanger-img' alt="schwanger2"/>
            </div>
          </div>
        </section>
        <section className='schwanger-head-label'>
          <h2>Yoga für Neu-Mamas</h2>          
          <p>Stärke dich mental in deiner Rolle als Mama durch diesen Kurs. Neben leichten körperlichen Übungen, gibt es unterstützenden und anregenden Austausch mit den anderen Mamas.</p>
        </section>

        <section className='schwanger-details'>
            <h3>Wann & wo findet der Kurs statt?</h3>
            <br/>
            <p><strong>Mittwochs 09.30 bis 11.00 Uhr</strong>, Dorfgemeinschaftshaus <br/> Benkeloher Str. 47 in 27383 Scheeßel-<strong>Ostervesede</strong></p>
            <br/>
            {/* <hr className='solid'/>
            <br/>
            <h3>Was bietet dir der Kurs?</h3>
            <ul>
              <li>Komme wieder langsam in Bewegung und spüre die positiven und entspannenden Wirkungen der Streckung und Dehnung durch Yoga</li>
              <li>Schenke deinem Körper neue Energie, indem du Belastungen wie wenig Schlaf oder unbequeme Stillpositionen ausgleichst</li>
              <li>Erfahre, warum ein kräftiger Beckenboden so wichtig für dein Leben ist</li>
              <li>Lerne, welche Schichten dein Beckenboden hat, wie du sie erspüren und kräftigen kannst</li>
              <li>Stärke dich mental in deiner Rolle als Mama!</li>
              <li>Trainiere alltagstaugliche Techniken für mehr Entspannung und ein positives Selbstwertgefühl</li>
              <li>Lerne, wie du körperliche Beschwerden durch gezielte Übungen lindern oder ihnen vorbeugen kannst</li>
            </ul>
            <br/> */}
            <hr className='solid'/>
            <br/>
            <h3>Was brauchst du für den Kurs?</h3>
            <br/>
            <p>Bitte bring dir folgendes zu jedem Kurstag mit:</p>
            <ul>
              <li>eine leichte (Woll-)<strong>Decke</strong></li>
              <li>ein <strong>Getränk</strong></li>
              <li>für dein <strong>Kind</strong> eine <strong>Decke</strong> zum darauf Liegen und <strong>Spielzeug</strong></li>
            </ul>
            <br/>
            {/* <p>Wenn du eine eigene Matte oder Blöcke hast, kannst du diese auch gerne mitbringen.</p> */}
            <br/>
            <hr className='solid'/>
            <br/>
            <h3>Träger und Kosten</h3>
            <br/>
            <p><strong>Träger</strong> ist der TuS Ostervesede.</p>
            <br/>
            <p><strong>Kosten</strong>:  im Jahresbeitrag für den TuS Ostervesede enthalten.</p>
            <br/>
            <p className='schwanger-center-p'><strong>Der Kurs ist fortlaufend, du kannst jederzeit einsteigen!</strong></p>
            <p className='schwanger-center-p'><strong>Melde dich einfach <a href='/Kontakt'>bei mir!</a> und komm zum kostenlosen Schnuppern vorbei!</strong></p>
        </section>

        <Footer color="yoga"/>
    </>
  );
}

export default YogaMamas;
