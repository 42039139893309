import React from 'react';
import NavigationBar from '../../navigationbar';
import Footer from '../../footer';

function Impressum() {
  return (
    <>
        <NavigationBar/>
        <section className='section impressum'>
          <h2>Impressum</h2>
          <br/>
          <br/>
          <h3>Herznah</h3>
          <p>Jasmin Stoll<br/>
            Zum Eulengrund 3<br/>
            27389 Lauenbrück
          </p>
          <p>Telefon: 01578 29 77 479</p>
          <p>E-Mail: herznah@gmx.de</p>

          <br/>
          <p>
          Disclaimer – rechtliche Hinweise<br/>
          § 1 Warnhinweis zu Inhalten<br/>
          Die kostenlosen und frei zugänglichen Inhalte dieser Webseite wurden mit größtmöglicher Sorgfalt erstellt.<br/>
          Der Anbieter dieser Webseite übernimmt jedoch keine Gewähr für die Richtigkeit und Aktualität der<br/>
          bereitgestellten kostenlosen und frei zugänglichen journalistischen Ratgeber und Nachrichten. Namentlich<br/>
          gekennzeichnete Beiträge geben die Meinung des jeweiligen Autors und nicht immer die Meinung des<br/>
          Anbieters wieder. Allein durch den Aufruf der kostenlosen und frei zugänglichen Inhalte kommt keinerlei<br/>
          Vertragsverhältnis zwischen dem Nutzer und dem Anbieter zustande, insoweit fehlt es am<br/>
          Rechtsbindungswillen des Anbieters.<br/>
          </p>
          <br/>
          <p>
            § 2 Externe Links<br/>
            Diese Website enthält Verknüpfungen zu Websites Dritter ("externe Links"). Diese Websites unterliegen der<br/>
            Haftung der jeweiligen Betreiber. Der Anbieter hat bei der erstmaligen Verknüpfung der externen Links die<br/>
            fremden Inhalte daraufhin überprüft, ob etwaige Rechtsverstöße bestehen. Zu dem Zeitpunkt waren keine<br/>
            Rechtsverstöße ersichtlich. Der Anbieter hat keinerlei Einfluss auf die aktuelle und zukünftige Gestaltung und<br/>
            auf die Inhalte der verknüpften Seiten. Das Setzen von externen Links bedeutet nicht, dass sich der Anbieter<br/>
            die hinter dem Verweis oder Link liegenden Inhalte zu Eigen macht. Eine ständige Kontrolle der externen<br/>
            Links ist für den Anbieter ohne konkrete Hinweise auf Rechtsverstöße nicht zumutbar. Bei Kenntnis von<br/>
            Rechtsverstößen werden jedoch derartige externe Links unverzüglich gelöscht.
          </p>
          <br/>
          <p>
            § 3 Urheber- und Leistungsschutzrechte<br/>
            Die auf dieser Website veröffentlichten Inhalte unterliegen dem deutschen Urheber- und<br/>
            Leistungsschutzrecht. Jede vom deutschen Urheber- und Leistungsschutzrecht nicht zugelassene<br/>
            Verwertung bedarf der vorherigen schriftlichen Zustimmung des Anbieters oder jeweiligen Rechteinhabers.<br/>
            Dies gilt insbesondere für Vervielfältigung, Bearbeitung, Übersetzung, Einspeicherung, Verarbeitung bzw.<br/>
            Wiedergabe von Inhalten in Datenbanken oder anderen elektronischen Medien und Systemen. Inhalte und<br/>
            Rechte Dritter sind dabei als solche gekennzeichnet. Die unerlaubte Vervielfältigung oder Weitergabe<br/>
            einzelner Inhalte oder kompletter Seiten ist nicht gestattet und strafbar. Lediglich die Herstellung von Kopien<br/>
            und Downloads für den persönlichen, privaten und nicht kommerziellen Gebrauch ist erlaubt.<br/>
            Die Darstellung dieser Website in fremden Frames ist nur mit schriftlicher Erlaubnis zulässig.<br/>
          </p>
          <br/>
          <p>
            § 4 Besondere Nutzungsbedingungen<br/>
            Soweit besondere Bedingungen für einzelne Nutzungen dieser Website von den vorgenannten Paragraphen<br/>
            abweichen, wird an entsprechender Stelle ausdrücklich darauf hingewiesen. In diesem Falle gelten im<br/>
            jeweiligen Einzelfall die besonderen Nutzungsbedingungen.
          </p>
          <br/>
          <br/>
        </section>

        <Footer/>
        
    </>
  );
}

export default Impressum;
