import React from "react";
import './CardWithPictureList.css';
import logo1 from '../assets/Yoga/SSy/pregnancy-yoga-and-fitness-concept.jpg';
import logo2 from '../assets/Yoga/Neu-Mamas/mother-with-little-baby-boy-practice-yoga(1).jpg';
import logo3 from '../assets/Yoga/Kinder/Kinderyoga.jpeg';
import logo4 from '../assets/Yoga/alle/group-of-young-sporty-people-in-uttanasana-exercise.jpg';

const pictureMapping = {
    'logo1': logo1,
    'logo2': logo2,
    'logo3': logo3,
    'logo4': logo4,
}

const CardWithPicturesList = ({ cards, showButton }) => {
    if (!Array.isArray(cards)) {
        // Handle the case where cards is not an array
        return null; // or display an error message
    }

    return (
        <div className="card-list-content">
            {cards.map((item, i) => (
                <div>
                    <div className="card-image-content" key={i}>
                        <img className="card-image" alt="card" src={pictureMapping[item.ImageSource]}/>
                    </div>
                    <div className="card-content">
                        <h3 className="card-header-label">{item.HeaderLabel}</h3>
                        <p className="card-main-label">{item.MainLabel}</p>
                        {
                            showButton?<a href={item.Url} className="card-button">mehr lesen</a> : <></>
                        }
                    </div>
                </div>
                
            ))}
        </div>
    );
}

export default CardWithPicturesList