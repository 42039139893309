import React from 'react';
import NavigationBar from '../../navigationbar';
import Footer from '../../footer';

import img1 from '../../assets/Yoga/Kinder/Familienyoga.jpeg';
import img2 from '../../assets/Yoga/Kinder/Kinderyoga.jpeg';

function YogaKinder() {
  return (
    <>
        <NavigationBar/>
        <section className='schwanger-head'>
          <div className='schwanger-img-container'>
            <div className='image-wrapper'>
              <img src={img1} className='schwanger-img' alt="schwanger1"/>
            </div>
          </div>
          <div className='schwanger-img-container'>
            <div className='image-wrapper'>
              <img src={img2} className='schwanger-img' alt="schwanger2"/>
            </div>
          </div>
        </section>
        <section className='schwanger-head-label'>
          <h2>Yoga für Familien und Kinder</h2>          
          <p>Diese beiden Kurse richten sich an Kinder (und deren Begleitung). Gemeinsam wollen wir durch spielerische Abenteuer Spaß an der körperlichen Bewegung haben, aber auch intensiv zur Ruhe kommen und unser Selbstvertrauen stärken.</p>
        </section>

        <section className='yoga-kinder-content'>
          <div className='yoga-alle-kosten-container yoga-kinder-center'>
            <div className='yoga-alle-kosten-column'>
              <h2>Familienyoga</h2>
              <p>Jeden 3. Freitag im Monat</p>
              <p>am 16.08., 20.09., 18.10., 15.11., 22.12. </p>
              <p><strong>16.00 bis 16.45 Uhr</strong>, in der alten Sparkasse (Dorfgemeinschaftshaus),  <br/> Bahnhofstraße 36 in 27389 <strong>Lauenbrück</strong></p>
              <p>Für Kinder zwischen <strong>3 und 6 Jahren</strong></p>
              <p>In Begleitung eines mitmachenden Erwachsenen, egal ob ein Elternteil, große Geschwister, Großeltern, Tante, Onkel……</p>
            </div>
            <div className='yoga-alle-kosten-column'>
              <h2>Kinderyoga</h2>
              <p>Jeden 3. Freitag im Monat</p>
              <p>am 16.08., 20.09., 18.10., 15.11., 22.12. </p>
              <p><strong>17.00 bis 17.45 Uhr</strong>, in der alten Sparkasse (Dorfgemeinschaftshaus),  <br/> Bahnhofstraße 36 in 27389 <strong>Lauenbrück</strong></p>
              <p>Für Kinder zwischen <strong>7 und 12 Jahren</strong></p>
            </div>
          </div>
        </section>

        <section className='yoga-kinder-kosten'>
          <h3>Was brauchst du für den Kurs?</h3>
          <br/>
          <p>Bitte bring dir folgendes zu jedem Kurstag mit:</p>
          <ul>
            <li>bequeme Kleidung</li>
            <li>Trinken</li>
            <li>eine eigene Yogamatte</li>
            <li>eine leichte (Woll-)Decke</li>
          </ul>
          <br/>
        </section>

        <section className='yoga-kinder-kosten yoga-kinder-background'>
            <h2>Kosten</h2>
            <br/>
            <p><strong>Familienyoga</strong>: 10 Euro pro Kind inklusive Begleitung</p>
            <br/>
            <p><strong>Kinderyoga</strong>: 7 Euro pro Kind</p>
            <p>Buchbar als Einzeltermine oder gleich bis zum Jahresende.<br/> Zur Buchung eines Kurses oder bei Fragen melde dich jederzeit bei<strong><a href='/kontakt'>mir</a>!</strong></p>
        </section>
        <Footer color="yoga"/>
    </>
  );
}

export default YogaKinder;
