import React from 'react';
import NavigationBar from '../../navigationbar';
import Footer from '../../footer';

import './gbv.css';

function Herzenswunsch() {
  return (
    <>
        <NavigationBar/>

        <section className="gbv-section">
          <div className="gbv-content">
            <h1>Mein Herzenswunsch</h1>
            <p className='gbv-text-left'>Mein Herzenswunsch ist es, Schwangerschaft und Geburt in unseren Köpfen positiver zu verankern, damit die Geburt deines Babys ein Fest der Liebe und Freude sein kann!<br/>
              In Filmen und auch in Berichten von Familie und Freunden werden meist nur negative Aspekte der Schwangerschaft und Geburt beleuchtet. Ja, Geburt kann weh tun. Warum man das so erlebt hat und ob das die Norm ist, darüber macht sich kaum einer Gedanken!<br/>
              Jetzt mal ehrlich: Warum sollte die Natur es so einrichten, dass unser Fortbestehen, also das Wichtigste für unsere Art überhaupt, soooo unglaublich schrecklich und schmerzhaft sein soll?!?! Klingt unlogisch? Ist es auch! Würden andere Säugetiere bei der Geburt unter unsagbaren Schmerzen schreien, käme gleich der nächste Wolf oder Löwe und würde Mutter samt Kind fressen... Die Annahme, Geburt muss halt schmerzhaft und schrecklich sein, ist also biologisch gesehen vollkommener Unsinn!<br/>
              Trotzdem ist genau das für viele Frauen pure und harte Realität!<br/>
              <strong>Du kannst lernen deinen Körper & Geist zu unterstützen, mit ihm zu arbeiten statt gegen ihn und dadurch deine Geburt zu einem kraftvollen und wunderschön-stärkenden Erlebnis werden lassen!</strong><br/>
              Trau dich und gehe mutig mit mir einen neuen Weg der Geburtsvorbereitung!<br/>
              Für die Geburt, die du dir innerlich wünscht und für die du, dein Körper und auch dein Baby von Natur aus gemacht sind!
            </p>
          </div>
        </section>

        <Footer color="geburtsvorbereitung"/>
    </>
  );
}

export default Herzenswunsch;
