// Footer.js

import React from 'react';
import './footer.css';

function Footer({color}) {
  let footerColor = "footer " + color;
  return (
    <section className={footerColor}>
      <a href='/impressum'>Impressum</a>
      {/* <a href='/datenschutz'>Datenschutz</a> */}
      {/* <a href='/cookies'>Cookie-Einstellungen</a> */}
    </section>
  );
}

export default Footer;
