import React from 'react';
import NavigationBar from '../../navigationbar';
import Footer from '../../footer';
import './gbv.css';


import image1 from '../../assets/Frauen gelber Hintergrund/Logo.jpg';
import image2 from '../../assets/Frauen gelber Hintergrund/4.png';
import image3 from '../../assets/Frauen gelber Hintergrund/3.jpg';
import image4 from '../../assets/Frauen gelber Hintergrund/Logo(1).png';

function AufbauKurs() {

  return (
    <>
        <NavigationBar/>
        
        <section>
          <div className='gbv-explain gbv-text-center'>
            <p>
              <strong>Schmerzen und andere negative Empfindungen während den Herausforderungen einer Geburt entstehen oft durch Angst oder Unsicherheiten und der daraus resultierenden körperlichen oder auch geistigen Anspannung!</strong><br/>
              Damit du und ihr mit den verschiedenen Herausforderungen während der Geburt umgehen könnt, erfahrt ihr in meinem Kurs, durch welche Zusammenhänge genau Schmerz entsteht und was dich/euch während der Geburt alles verunsichern könnte. Anschließend lernt ihr verschiedene Methoden der Entspannung, Atmung und des positiven Denkens kennen, um das ganz individuell für euch passende zu finden, auch nach einer vorherigen negativen Geburtserfahrung!<br/>
              Mit einer klaren und persönlichen Strategie könnt ihr dann auch zu Hause weiter üben, um selbstsicherer, gestärkt und zuversichtlich in die Geburt und das Wochenbett zu gehen!<br/>
              <br/>
              <strong>Mach mit bei meinem Kurs "STARK in die Geburt und Wochenbett"!<br/>
                Oder buche dir einen persönlichen Termin!<br/>
                <br/>
                Ich freue mich auf dich/euch!
              </strong>
            </p>
          </div>
        </section>

        <section className="gbv-section-dynamic">
          <div className="gbv-content-dynamic">
            <div className='gbv-explain gbv-text-center gbv-padding-100'>
              <h2>Schau dir die Bestandteile meiner Vorbereitung auf deine Geburt ohne Angst genau an!</h2>
              <p>Es gibt 4 wichtige Hauptbestandteile.</p>
            </div>
          </div>
        </section>

        <section>
          <div className="gbv-image-text-container">
            <div className="gbv-image-text-item">
                <img className='gbv-image' src={image1} alt="fokussierende Yoga Frau"/>
                <h2>Entspannung</h2>
                <p className='gbv-text'>
                  Du denkst, Geburt tut nunmal weh und du kannst nichts dagegen tun?<br/>
                  Weit gefehlt! Warum?<br/>
                  Weil Geburt nicht automatisch<br/>
                  schmerzhaft ist!<br/>
                  Ja, es entsteht ein Druck/Ziehen durch die Muskelarbeit deiner Gebärmutter und auch durch dein Baby, das sich durch den Geburtskanal bewegt. Aber dieser Druck ist positiv und kann dich bei deiner Geburt unterstützen! Du selbst störst durch (unbewusste) Ängste und Unsicherheiten deinen Körper bei seinem Geburtsprozess!<br/>
                  Dadurch entstehen Schmerzen!
                </p>
                <br/>
                <p className='gbv-text'>
                <strong >Je entspannter du körperlich und geistig bist, desto schmerzarmer wird deine Geburt verlaufen! </strong>
                </p>
            </div>
            <div class="gbv-image-text-item">
                <img className='gbv-image' src={image2} alt="meditierende Yoga Frau"/>
                <h2>Atmung</h2>
                <p className='gbv-text'>
                  Deine <strong>Atmung</strong> ist während deiner Geburt mit das <strong>wichtigste Werkzeug</strong>, das du nutzen kannst, <strong>um dich zu entspannen!</strong><br/>
                  Mit einer tiiiefen Atmung versorgst du dich und dein Baby mit Sauerstoff.<br/>
                  Durch eine tiefe Atmung vermittelst du deinem Körper Sicherheit und Geborgenheit!<br/>
                  Eine entspannte Muskulatur bringt dir einen entspannten Beckenboden und eine entspannte Gebärmutter, was wiederum die Öffnung des Muttermundes vereinfacht und auch schneller machen kann!
                </p>
            </div>
          </div>
        </section>

        <section>
          <div class="gbv-image-text-container">
            <div class="gbv-image-text-item">
                <img className='gbv-image' src={image3} alt="tanzende yoga frau"/>
                <h2>positives Denken</h2>
                <p className='gbv-text'>Positives Denken ist viel mehr als sich einfach nur "etwas schön zu reden".<br/>
                  Du kannst die <strong>Macht deiner Gedanken</strong> nutzen, um deine <strong>Wahrnehmung</strong> und deinen <strong>Fokus umzubauen.</strong><br/>
                  <strong>Du empfindest die Geburt und auch die Wehen ganz anders, wenn du grundlegend positiv dazu eingestellt bist!</strong><br/>
                  Und auch wenn irgendetwas nicht läuft, wie du dir das vorgestellt hast oder dir dies oder das vorgeschlagen wird, kannst du leichter damit umgehen und den passenden Weg für dich persönlich finden!
                </p>
            </div>
            <div class="gbv-image-text-item">
                <img className='gbv-image' src={image4} alt="Idee Yoga Frau"/>
                <h2>Wissen</h2>
                <p className='gbv-text'><strong>"Wissen ist Macht!"</strong> (Francis Bacon), den Spruch hast du bestimmt schon gehört!<br/>
                  Er ist soo wahr!<br/>
                  Viele scheuen sich davor, sich mit der Geburt richtig auseinander zu setzen, weil sie Angst vor diesen ganzen Komplikationen und auch ihren eigenen Ängsten dazu haben!<br/>
                  <strong>Wenn du dich nicht damit beschäftigst, beeinflussen dich Ängste und Unsicherheiten trotzdem - unterbewusst- ohne, dass du es merkst!</strong><br/>
                  Also setze dich lieber damit auseinander!<br/>
                  Du kannst dabei nur gewinnen!<br/>
                  Eigne dir Wissen an, damit du selbstbestimmte Entscheidungen treffen kannst und dich auch im Nachhinein wohl damit fühlen kannst!
                </p>
            </div>
          </div>
        </section>

        <Footer color="geburtsvorbereitung"/>
    </>
  );
}

export default AufbauKurs;
